import React from 'react';

const Circle = ({ className }) => (
  <svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="4.25" stroke="#BCBCBC" strokeWidth="1.5" />
  </svg>
);

const BrowserFrame = ({ className, children }) => (
  <div className={`rounded-[4px] shadow ${className}`}>
    {/* <BrowserTitle className="" /> */}
    <div className="h-[20px] py-[5px] bg-sp-primary-light-grey rounded-t-[4px] flex items-center">
      <Circle className="h-[10px] w-[10px] ml-[6px]" />
      <Circle className="h-[10px] w-[10px] mx-[3px]" />
      <Circle className="h-[10px] w-[10px] ml-[3px] mr-auto" />
      <Circle className="h-[10px] w-[10px] mr-[6px]" />
    </div>
    <div className="">{children}</div>
  </div>
);

export default BrowserFrame;
