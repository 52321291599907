import React from 'react';

const EmailLg = ({ className }) => (
  <svg className={className} viewBox="0 0 149 149" fill="none">
    <g styles={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="105.05" cy="56.1355" rx="41.9408" ry="40.3581" fill="#C2EDE0" />
    </g>
    <path
      d="M121.901 49.0266H7.82374C7.357 49.0266 6.97864 49.4022 6.97864 49.8655V127.192C6.97864 127.656 7.357 128.031 7.82374 128.031H121.901C122.368 128.031 122.746 127.656 122.746 127.192V49.8655C122.746 49.4022 122.368 49.0266 121.901 49.0266Z"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path d="M6.97864 49.8655L64.8625 99.3023L121.901 49.0266" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M7.8241 128.031L54.1543 88.3855" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M75.5713 88.3855L121.901 128.031" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
  </svg>
);

export default EmailLg;
