import * as React from 'react';

import Container from '../Container';

const FeatureSection = ({ title, imageContent, imageLeftOrRight = 'right', className, children }) => (
  <section className={`bg-white text-sp-dark ${className}`}>
    <Container className="my-24 ">
      <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className={`${imageLeftOrRight === 'left' && 'col-start-2'} text-lg text-sp-black font-light`}>
          <h2 className="text-5xl font-normal">{title}</h2>
          {children}
        </div>
        <div className={`${imageLeftOrRight === 'left' && 'col-start-1'} flex flex-col items-center mt-12 md:mt-0`}>{imageContent}</div>
      </div>
    </Container>
  </section>
);

export default FeatureSection;
