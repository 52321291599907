import React from 'react';

const SubscriptionLg = ({ className }) => (
  <svg className={className} viewBox="0 0 149 149" fill="none">
    <g styles={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="45.4464" cy="43.8642" rx="41.9408" ry="40.3581" fill="#C2EDE0" />
    </g>
    <path d="M58.1649 58.5386V29.7507H29.6365" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
    <path d="M109.231 111.509V140.296H137.759" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
    <path
      d="M42.919 125.742C32.5917 115.32 26.2128 100.926 26.2128 85.024C26.2128 69.1215 32.6031 54.7276 42.919 44.3064"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M123.621 44.3064C133.948 54.7276 140.327 69.1215 140.327 85.024C140.327 100.926 133.937 115.32 123.621 125.742"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <g transform="translate(6.2400000, 0.0)">
      <path
        d="M86.956 67.9323L84.4517 71.9803C81.8049 70.3417 79.033 69.3373 76.1388 68.9757L75.5768 68.9055V69.4718V80.1662V80.6008L76.0072 80.6614C77.3066 80.8442 78.51 81.0948 79.5959 81.4118L79.5972 81.4121C80.6871 81.727 81.7337 82.1547 82.7372 82.6846C83.7315 83.2097 84.5704 83.8179 85.2506 84.5167L85.2506 84.5167L85.2534 84.5195C85.9229 85.1966 86.461 86.0412 86.8517 87.051C87.2382 88.0497 87.4253 89.1679 87.4145 90.4131C87.359 93.4024 86.2888 95.7946 84.2098 97.6437C82.1051 99.5157 79.392 100.7 76.0313 101.164L75.5998 101.224V101.659V106.75H71.2545V101.764V101.305L70.7975 101.266C68.4192 101.061 66.0875 100.559 63.8123 99.7392C61.7065 98.9802 59.9146 98.0113 58.4369 96.8293L61.1026 92.8484C62.2004 93.7045 63.5385 94.4568 65.1177 95.0936C66.9149 95.8299 68.7709 96.3049 70.6845 96.5304L71.243 96.5963V96.0339V85.3743V84.9498L70.8242 84.8809L70.331 84.7997L70.331 84.7996L70.3232 84.7985C69.0942 84.616 67.9588 84.3657 66.9279 84.0598L66.9265 84.0594C65.8996 83.7579 64.9134 83.3327 63.9448 82.7924C62.9964 82.257 62.1921 81.6391 61.5458 80.9311C60.9077 80.232 60.4 79.3746 60.0177 78.337C59.6501 77.3082 59.4636 76.1746 59.4856 74.9089L59.4856 74.9058C59.5185 71.9769 60.5758 69.6075 62.6334 67.7688C64.7244 65.9105 67.4351 64.7494 70.8063 64.319L71.243 64.2633V63.823V58.9758H75.5768V63.7418V64.2017L76.0351 64.2401C79.9441 64.5677 83.5825 65.7969 86.956 67.9323ZM66.2823 71.0344C65.2971 71.9061 64.7292 72.9834 64.6032 74.2378H64.5742L64.5652 74.7286C64.5395 76.1167 65.0618 77.2582 66.1256 78.069C67.1432 78.856 68.6637 79.4321 70.6411 79.8438L71.243 79.9691V79.3543V69.5878V68.99L70.6546 69.0957C68.8458 69.4206 67.3771 70.0657 66.2823 71.0344ZM76.1855 85.6981L75.5768 85.5626V86.1862V95.9063V96.5105L76.1704 96.3975C77.9973 96.0496 79.4832 95.4065 80.6092 94.4556L80.6116 94.4535C81.7592 93.4716 82.3682 92.2238 82.381 90.7374V90.7331V90.7264C82.4059 89.4146 81.8355 88.3408 80.7333 87.5243C79.6672 86.7346 78.1453 86.1346 76.1855 85.6981Z"
        fill="black"
        stroke="white"
      />
    </g>
  </svg>
);

export default SubscriptionLg;
