import React from 'react';

const RestLg = ({ className }) => (
  <svg className={className} viewBox="0 0 152 152" fill="none">
    <ellipse
      cx={103.437}
      cy={67.539}
      fill="#C2EDE0"
      rx={42.722}
      ry={41.11}
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <path
      fill="#000"
      d="M19.36 61.153H31.54c6.924 0 10.736 3.11 10.736 8.698 0 5.324-4.14 8.742-10.714 8.742h-.24l10.8 13.234h-4.776L26.81 78.593h-3.594v13.234H19.36V61.153Zm12.094 3.615h-8.239v10.32h8.239c4.513 0 6.814-1.797 6.814-5.237 0-3.396-2.367-5.083-6.814-5.083ZM70.44 88.211v3.616H48.663V61.153h21.56v3.615H52.518v9.793h17.134v3.637H52.518v10.013h17.923ZM99.877 64.067 97.905 67.2c-2.497-1.687-5.543-2.827-9.092-2.827-4.492 0-7.69 2.016-7.69 5.325 0 2.782 2.344 4.206 6.945 4.776l2.695.328c5.192.636 9.64 2.74 9.64 8.151 0 6.113-5.543 9.312-12.182 9.312-4.36 0-9.136-1.512-12.094-3.988l2.103-3.067c2.125 1.84 6.091 3.396 9.991 3.396 4.58 0 8.173-1.863 8.173-5.324 0-2.849-2.564-4.207-7.34-4.799l-2.849-.35c-5.06-.614-9.092-2.958-9.092-8.194 0-5.916 5.258-9.225 11.722-9.225 4.623 0 8.15 1.359 11.042 3.353ZM128.265 61.153v3.658h-10.451v27.016h-3.856V64.81h-10.473v-3.658h24.78ZM12 64.162h-1.018c-2.437 0-4.091 1.417-3.946 4.252l.128 2.388c.145 3.078-1.2 5.148-3.728 6.025 2.528.876 3.873 2.947 3.728 6.024l-.128 2.388c-.145 2.835 1.51 4.252 3.946 4.252H12v2.836h-1.018c-4.655 0-7.273-2.742-7.018-7.443l.127-2.387c.145-2.835-1.51-4.253-4.091-4.253V75.41c2.582 0 4.236-1.418 4.09-4.253l-.126-2.387c-.255-4.7 2.363-7.442 7.018-7.442H12v2.835ZM135 89.492h1.018c2.437 0 4.091-1.418 3.946-4.253l-.128-2.388c-.145-3.077 1.2-5.148 3.728-6.024-2.528-.877-3.873-2.947-3.728-6.025l.128-2.388c.145-2.835-1.509-4.252-3.946-4.252H135v-2.835h1.018c4.655 0 7.273 2.742 7.018 7.442l-.127 2.387c-.145 2.835 1.509 4.253 4.091 4.253v2.835c-2.582 0-4.236 1.418-4.091 4.253l.127 2.387c.255 4.7-2.363 7.443-7.018 7.443H135v-2.835Z"
    />
  </svg>
);

export default RestLg;
