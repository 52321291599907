import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import BrowserFrame from '../components/BrowserFrame';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import FeatureSection from '../components/sections/FeatureSection';
import SubscriptionLg from '../components/icons/SubscriptionLg';
import RestLg from '../components/icons/RestLg';
import EmailLg from '../components/icons/EmailLg';

const FeaturesPage = () => (
  <Layout>
    <Seo title="Subscribe Pro Subscription Commerce Platform Features" />

    <Header graphic={1}>Subscribe Pro Features</Header>

    <FeatureSection
      title="Ordering Automation"
      imageContent={<SubscriptionLg className="w-[16rem] h-auto" />}
      imageLeftOrRight="right"
    >
      <p className="mt-4">
        Customize the frequency and timing of recurring orders to fit your business model and customer needs. Utilize smart
        algorithms to automatically retry failed transactions, reducing involuntary churn.
      </p>
      <p className="mt-5">
        Our advanced order retry rules allow you to fine tune the timing of order retries and customer notifications to maximize
        success rate and minimize churn.
      </p>
    </FeatureSection>

    <FeatureSection
      title="Advanced Payments Platform"
      imageContent={
        <BrowserFrame className="max-w-[900px]">
          <StaticImage
            className="object-contain "
            src="../images/sp_payments_docs.png"
            width={900}
            quality={95}
            objectFit="contain"
            alt="Subscribe Pro Merchant App | Manager your loyal subscription customers and their data"
          />
        </BrowserFrame>
      }
      imageLeftOrRight="left"
    >
      <p className="mt-4">
        Subscribe Pro Payments allows you to accept customer payments via credit card and other common payments methods. We
        provide integration support for hundreds of payment providers and many advanced features.
      </p>
      <p className="mt-5">
        Our payments platform offers all of the following advanced features:
        <ul className="ml-4 mt-5 bullet-circle-check">
          <li>3D Secure 2 (3DS2) Authentication</li>
          <li>Account Updater (AU)</li>
          <li>Gateway Tokenization</li>
          <li>Payment Orchestration / Payment Routing</li>
          <li>Stored Credentials Support</li>
        </ul>
      </p>
    </FeatureSection>

    <FeatureSection
      title="Self-Service Customer Portal"
      imageContent={
        <BrowserFrame className="max-w-[900px]">
          <StaticImage
            className="object-contain "
            src="../images/my_subs_sfra_1.png"
            width={900}
            quality={95}
            objectFit="contain"
            alt="Subscribe Pro Merchant App | Manager your loyal subscription customers and their data"
          />
        </BrowserFrame>
      }
      imageLeftOrRight="right"
    >
      <p className="mt-4">
        Subscribe offers our Hosted My Subscriptions Page, a branded, hosted page where customers can easily manage their own
        subscriptions, check status and order additional items.
      </p>
      <p className="mt-4">
        The My Subscriptions Page is fully customizable and can be integrated into your existing website or used as a standalone
        page. The My Subscriptions Page works on all devices and the theme can be fully customized to match your brand.
      </p>
      <p className="mt-4">
        If you need even more flexibility, Subscribe Pro&apos;s APIs allow you to build a completely bespoke customer portal.
      </p>
    </FeatureSection>

    <FeatureSection
      title="Powerful and Flexible APIs"
      imageContent={<RestLg className="w-[16rem] h-auto" />}
      imageLeftOrRight="left"
    >
      <p className="mt-4">
        We offer APIs covering all features available in the Subscribe Pro platform. The Subscribe Pro API is a standard
        REST-style API that accepts and returns JSON. We are committed to making sure all data stored by our subscription commerce
        platform is available via API and that all operations may be completed via API as well.
      </p>

      <p className="mt-4">
        We have architected our platform to provide a solid foundation for the long term. Our support team is never more than a
        phone call away. And when you need our help, we strive to provide truly world class support.
      </p>
    </FeatureSection>

    <FeatureSection
      title="Customer Service Tools"
      imageContent={
        <BrowserFrame className="max-w-[900px]">
          <StaticImage
            className="object-contain "
            src="../images/sp_merchant_app_customers_demo.png"
            width={900}
            quality={95}
            objectFit="contain"
            alt="Subscribe Pro Merchant App | Manager your loyal subscription customers and their data"
          />
        </BrowserFrame>
      }
      imageLeftOrRight="right"
    >
      <p className="mt-4">
        The Subscribe Pro Merchant App is powerful customer service tool. Your subscribers are your most loyal customers and we
        believe it is essential your customer service team has access to the best tools to manage those customers data.
      </p>

      <p className="mt-4">
        We equip your customer service team with a toolkit for managing subscription queries, updating customer information, and
        solving problems—all within a single easy-to-use and flexible app.
      </p>
    </FeatureSection>

    <FeatureSection
      title="Email and SMS Notifications"
      imageContent={<EmailLg className="w-[16rem] h-auto" />}
      imageLeftOrRight="left"
    >
      <p className="mt-4">
        Subscribe Pro&apos;s Transactional Email Rules allow you to customize notification emails and / or SMS messages based on
        specific triggers or customer behaviors.
      </p>

      <p className="mt-4">
        Subscribe Pro also allows you to send notifications via your existing email provider with ease. We offer a direct
        integration with Klaviyo and integrations via our Webhooks system with other email providers like Salesforce Marketing
        Cloud, Dotdigital or SendGrid.
      </p>
    </FeatureSection>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default FeaturesPage;
